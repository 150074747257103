/*	========================================================================
	*	DOM-based	Routing
	*	Based	on	http://goo.gl/EUTi53	by	Paul	Irish
	*
	*	Only	fires	on	body	classes	that	match.	If	a	body	class	contains	a	dash,
	*	replace	the	dash	with	an	underscore	when	adding	it	to	the	object	below.
	*
	*	.noConflict()
	*	The	routing	is	enclosed	within	an	anonymous	function	so	that	you	can
	*	always	reference	jQuery	with	$,	even	when	in	.noConflict()	mode.
	*	========================================================================	*/

/*
=======================================
//! Setup Variables
=======================================
*/

var $=jQuery,windowHeight = $(window).height(),windowWidth = $(window).width(),beforeElHeight,baseUrl = '/wp-content/themes/alt-fruity/dist/scripts/';

/*
=======================================
//! Resize Element Function
=======================================
*/

function resizeElement($element, $divide, $axis, $excess){
	if($axis === 'height'){$($element).css('height', ((windowHeight / $divide) + $excess) + 'px').css('top', -$excess + 'px');}
	else if($axis === 'width'){$($element).css('width', (windowWidth / $divide) + 'px');}
	else{console.log('$axis not defined.');}
}

/*
=======================================
//! Square Off Elements
=======================================
*/

function squareElements($element, $axis){
	var $thisHeight = $element.height, $thisWidth = $element.width;
	if($axis === 'height'){
		$element.width($thisHeight);
	}
	else{
		$element.height($thisWidth);
	}
}

/*
=======================================
//! Setup Position Of Element Function
=======================================
*/

function setPosition($before, $element, $excess){
	beforeElHeight = $($before).height();
	$($element).css('marginTop', (beforeElHeight - $excess) + 'px');
}

/*
=======================================
//! Google Map
=======================================
*/

function getGoogleMap(element){



}

var fadeTimer = 250;
var that;
var waypoints = [];

(function($)	{

		var	Ctrl	=	{
				//	All	pages
				'common':	{
						init:	function()	{
							
						},
						finalize:	function()	{

							function fadeInFunction( thisObj, fadeTiming ){
								setTimeout(function(){
									$(thisObj).velocity({ opacity: 1, top: 0 }, 1000, function() { console.log("Hi"); });
								}, fadeTiming);
							}

							$(document).ready(function(){

								console.log('ready');
								
								$('.mobile-button button').click(function(){
									if($(this).hasClass('close')){
										$(this).removeClass('close');
										$(this).parent().removeClass('close');
										$('nav').stop().fadeOut().removeClass('show');
									}
									else{
										$(this).addClass('close');
										$(this).parent().addClass('close');
										$('nav').stop().fadeIn().addClass('show');
									}
								});
								
								//Fading Functions
								$('.fadeIn').each(function(){
									fadeInFunction( $(this), fadeTimer );
									fadeTimer += 100;
								});
								
								var i = 0;

								//End Fading Functions
								console.log('derpderp');
								//Resizing Functions
								var sizeTrigger;
								$(window).resize(function(){
									var windowWidth = $(window).width();
									if(windowWidth > 650){
										$('nav').css('display', 'block');
										sizeTrigger = true;
									}
									if(windowWidth < 650 && sizeTrigger === true){
										$('nav').css('display', 'none');
										sizeTrigger = false;
									}

									$('.alumni').each(function(){
										//Original image size
										var OrgWidth = 1662;
										var OrgHeight = 614;
										var OrgRatio = OrgHeight / OrgWidth;

										//Actual width
										var thisWidth = $(this).width();

										//Change Height
										$(this).css('height', (thisWidth * OrgRatio) + 'px');
									});
								});


								
								$('.checkForResize').each(function(){
									var currentHeight = $(this).height();
									var that = $(this);
									var maxHeight = 0;
									that.find('.checkForSize').each(function(){
										if($(this).height() > maxHeight){
											maxHeight = $(this).height();
										}
									});
									
									if(maxHeight < currentHeight){
										console.log('attempted');
										that.css('height', maxHeight + 20 + 'px');
									}
									console.log(maxHeight);
									//Check child elements for the max size.
								});

								//Sort the alumni thing out
								setTimeout(function(){
									console.log('This should be working');
									$('.alumni-body').each(function(){
										//we need to sort the ratio out to allow the curves to show.
										console.log('This should be working');

										//Original image size
										var OrgWidth = 1662;
										var OrgHeight = 614;
										var OrgRatio = OrgHeight / OrgWidth;

										//Actual width
										var thisWidth = $(this).width();

										//Change Height
										$(this).css('height', (thisWidth * OrgRatio) + 'px');

										//Do the slider thing.

										$(this).find('.alumni-people').first().addClass('active');

										function moveAlumni(){
											if($('.alumni-people.active').next().length !== 0){
												$('.alumni-people.active').velocity({ opacity: 0 }, 1000, function(){
													$(this).removeClass('active');
													$(this).next().addClass('active').velocity({ opacity: 1 }, 1000);
													$('.circ').removeClass('active');
													var id = $('.alumni-people.active').attr('id').split('person-')[1];
													$('#circ-'+id).addClass('active');
												});
											}
											else{
												$('.alumni-people.active').velocity({ opacity: 0 }, 1000, function(){
													$(this).removeClass('active');
													$('.alumni-people').first().addClass('active').velocity({ opacity: 1 }, 1000);
													$('.circ').removeClass('active');
													var id = $('.alumni-people.active').attr('id').split('person-')[1];
													$('#circ-'+id).addClass('active');
												});
											}
										}

										$('.slider').anyslider({
											animation: "fade",
											showControls: false,
											showBullets: false,
											interval: 9000,
											pauseOnHover: false,
											speed: 1000,
										});

										moveAlumni();

										var runAlumni = setInterval(moveAlumni, 9000);

										$('.circ').click(function(){
											var that = $(this);
											var thatId = $(this).attr('id').split('circ-')[1];
											clearInterval(runAlumni);
											$('.alumni-people.active').velocity({ opacity: 0 }, 1000, function(){
												$('.alumni-people.active').removeClass('active');
												$('#person-'+thatId).addClass('active').velocity({ opacity: 1 }, 1000);
												$('.circ').removeClass('active');
												$('#circ-'+thatId).addClass('active');
											});
											runAlumni = setInterval(moveAlumni, 9000);
										});

									});
									//End that.
								}, 2000);

								//End Resizing Functions
								
								//Check for Gmap
								$('#g_map').each(function(){
									getGoogleMap($(this));
								});
								//End Check
								

							});
						}
				},					
				//	Home	page
				'home':	{
						init:	function()	{
								
						},
						finalize:	function()	{
							
						}
				},
				
				'about_us':	{
						init:	function()	{
								
						}
				},
				
				'news': {
						init: function(){
							
						},
						
						finalize: function(){
							
						}
				}
		};

		//	The	routing	fires	all	common	scripts,	followed	by	the	page	specific	scripts.
		//	Add	additional	events	for	more	control	over	timing	e.g.	a	finalize	event
		var	UTIL	=	{
				fire:	function(func,	funcname,	args)	{
						var	fire;
						var	namespace	=	Ctrl;
						funcname	=	(funcname	===	undefined)	?	'init'	:	funcname;
						fire	=	func	!==	'';
						fire	=	fire	&&	namespace[func];
						fire	=	fire	&&	typeof	namespace[func][funcname]	===	'function';

						if	(fire)	{
								namespace[func][funcname](args);
						}
				},
				loadEvents:	function()	{
						//	Fire	common	init	JS
						UTIL.fire('common');

						//	Fire	page-specific	init	JS,	and	then	finalize	JS
						$.each(document.body.className.replace(/-/g,	'_').split(/\s+/),	function(i,	classnm)	{
								UTIL.fire(classnm);
								UTIL.fire(classnm,	'finalize');
						});

						//	Fire	common	finalize	JS
						UTIL.fire('common',	'finalize');
				}
		};

		//	Load	Events
		$(document).ready(UTIL.loadEvents);

})(jQuery);	//	Fully	reference	jQuery	after	this	point.
